import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    List,
    ListItem,
    MenuItem,
    TextField,
    Typography,
} from '@mui/material';
import { Team } from '@spec/Organization';
import { Talent } from '@spec/Talent';
import { Workplace } from '@spec/Workplace';
import dayjs from 'dayjs';
import { NO_GRADE_LONG_TEXT, gradeToString } from '../../../domains/Talent';
import { useMeContext } from '../../../queries/me';
import { FilterCondition, FilterForm, FilterRow } from '../../FilterForm';
import { FlexBox } from '../../FlexBox';
import { HelpTip } from '../../HelpTip';
import { MultiSelect } from '../../MultiSelect';
import { TeamDrill } from '../../TeamDrill';
import { TenantContent } from '../../TenantContent';
import { useFilterFormContext } from './Context';
import {
    defaultListFilterCondition,
    isDisplayMode,
    isEnrollment,
    isHiringCategory,
    isSignInType,
    isSortOrder,
} from './filter';

const NOT_SELECTED = 'none';

// export for index.filterTalents()
export const NO_GRADE_TEXT = NO_GRADE_LONG_TEXT;
export const NO_WORKPLACE_TEXT = '未設定';

const FIRST_LABEL_WIDTH = 3;

interface Props {
    talents: Talent[];
    teams: Team[];
    workplaces: Workplace[];
}

export const ConditionForm = (props: Props) => {
    const { updateFilterCondition, viewCondition, updateViewCondition } = useFilterFormContext();
    return (
        <Box>
            <FilterForm>
                <TenantContent
                    carta={<Carta {...props} />}
                    treasure2024={<Treasure2024 {...props} />}
                />
                <FilterRow>
                    <FilterCondition label="並び順" labelWidth={FIRST_LABEL_WIDTH}>
                        <TextField
                            select
                            value={viewCondition.sortOrder}
                            onChange={(e) => {
                                const sortOrder = e.target.value;
                                if (isSortOrder(sortOrder)) {
                                    updateViewCondition({ sortOrder });
                                }
                            }}
                        >
                            <MenuItem value="joinedAt">入社日</MenuItem>
                            <MenuItem value="employeeCode">社員番号</MenuItem>
                            <MenuItem value="grade">グレード</MenuItem>
                            <MenuItem value="leftAt">退職日</MenuItem>
                        </TextField>
                    </FilterCondition>
                    <FilterCondition>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={viewCondition.sortReverse}
                                    onChange={(e) =>
                                        updateViewCondition({ sortReverse: e.target.checked })
                                    }
                                    name="sortReverse"
                                    size="small"
                                />
                            }
                            label={<Typography>逆順に並べる</Typography>}
                        />
                    </FilterCondition>
                    <FilterCondition label="1ページの表示件数">
                        <TextField
                            select
                            value={viewCondition.itemsPerPage}
                            onChange={(e) => {
                                updateViewCondition({ itemsPerPage: Number(e.target.value) });
                            }}
                        >
                            {[20, 30, 50, 100].map((v) => (
                                <MenuItem key={v} value={v}>
                                    {v}件
                                </MenuItem>
                            ))}
                        </TextField>
                    </FilterCondition>
                    <FilterCondition label="表示モード">
                        <TextField
                            select
                            value={viewCondition.displayMode}
                            onChange={(e) => {
                                const displayMode = e.target.value;
                                if (isDisplayMode(displayMode)) {
                                    updateViewCondition({ displayMode });
                                }
                            }}
                        >
                            <MenuItem value="list">リスト</MenuItem>
                            <MenuItem value="gallery">ギャラリー</MenuItem>
                        </TextField>
                    </FilterCondition>
                </FilterRow>
            </FilterForm>
            <Box mt={2} textAlign="center">
                <Button
                    variant="outlined"
                    size="large"
                    onClick={() => {
                        updateFilterCondition(defaultListFilterCondition);
                    }}
                >
                    絞込み条件をリセット
                </Button>
            </Box>
        </Box>
    );
};

const Carta = (props: Props) => {
    const { filterCondition, updateFilterCondition } = useFilterFormContext();
    const { grants } = useMeContext();
    const years = new Set(props.talents.map((v) => dayjs(v.joinedAt).year()));
    // filter default joined year
    years.delete(1990);
    const grades = new Set(props.talents.map((v) => gradeToString(v.grade, NO_GRADE_TEXT)));
    const employmentStatuses = new Set(props.talents.map((v) => v.employment.employmentStatus));
    const secondmentTypes = new Set(
        props.talents
            .map((v) => v.secondment?.assignmentType)
            .flatMap((v) => (v === undefined ? [] : [v]))
    );
    const tagCategories = new Set(props.talents.map((v) => v.tags.map((t) => t.category)).flat());
    const tagTerms = new Set(
        props.talents
            .map((v) =>
                v.tags
                    .filter((t) => filterCondition.tagCategories.includes(t.category))
                    .map((t) => t.term)
            )
            .flat()
    );
    const tagLabels = new Set(
        props.talents
            .map((v) =>
                v.tags
                    .filter((t) => filterCondition.tagCategories.includes(t.category))
                    .map((t) => t.label)
            )
            .flat()
    );
    return (
        <>
            <FilterRow>
                <FilterCondition label="入社年" labelWidth={FIRST_LABEL_WIDTH}>
                    <MultiSelect
                        values={filterCondition.joinedYears}
                        setItems={(v) =>
                            updateFilterCondition({
                                joinedYears: v.map(Number),
                            })
                        }
                    >
                        {[...years]
                            .sort()
                            .reverse()
                            .map((v) => (
                                <MenuItem key={v} value={v}>
                                    {v}
                                </MenuItem>
                            ))}
                    </MultiSelect>
                </FilterCondition>
                <FilterCondition label="入社区分">
                    <TextField
                        select
                        value={filterCondition.hiringCategory}
                        onChange={(e) => {
                            const hiringCategory = e.target.value;
                            if (isHiringCategory(hiringCategory)) {
                                updateFilterCondition({
                                    hiringCategory,
                                });
                            }
                        }}
                    >
                        <MenuItem value="all">指定なし</MenuItem>
                        <MenuItem value="newGraduate">新卒</MenuItem>
                        <MenuItem value="experienced">中途</MenuItem>
                    </TextField>
                </FilterCondition>
                <FilterCondition flexGrow={1} label="雇用形態">
                    <MultiSelect
                        fullWidth
                        values={filterCondition.employmentStatus}
                        setItems={(v) => updateFilterCondition({ employmentStatus: v })}
                    >
                        {[...employmentStatuses].sort().map((v) => (
                            <MenuItem key={v} value={v}>
                                {v}
                            </MenuItem>
                        ))}
                    </MultiSelect>
                </FilterCondition>
                <FilterCondition
                    label={
                        <FlexBox>
                            社員区分
                            <HelpTip title="社員番号の先頭1〜2文字で検索できます" />
                        </FlexBox>
                    }
                >
                    <TextField
                        value={filterCondition.employeeCode}
                        onChange={(e) => {
                            updateFilterCondition({
                                employeeCode: e.target.value,
                            });
                        }}
                        sx={{ width: '3rem' }}
                        slotProps={{
                            htmlInput: { maxLength: 2 },
                        }}
                    />
                </FilterCondition>
                <FilterCondition label="勤務地">
                    <TextField
                        select
                        fullWidth
                        value={filterCondition.workplace ?? NOT_SELECTED}
                        onChange={(e) => {
                            updateFilterCondition({
                                workplace: e.target.value === NOT_SELECTED ? null : e.target.value,
                            });
                        }}
                    >
                        <MenuItem value={NOT_SELECTED}>指定なし</MenuItem>
                        <MenuItem value={NO_WORKPLACE_TEXT}>{NO_WORKPLACE_TEXT}</MenuItem>
                        {[...props.workplaces].map((v) => (
                            <MenuItem key={v.id} value={v.name}>
                                {v.name}
                            </MenuItem>
                        ))}
                    </TextField>
                </FilterCondition>
                <FilterCondition label="ログイン">
                    <TextField
                        select
                        value={filterCondition.signIn}
                        onChange={(e) => {
                            const signIn = e.target.value;
                            if (isSignInType(signIn)) {
                                updateFilterCondition({ signIn });
                            }
                        }}
                    >
                        <MenuItem value="all">指定なし</MenuItem>
                        <MenuItem value="allowed">可</MenuItem>
                        <MenuItem value="restricted">不可</MenuItem>
                    </TextField>
                </FilterCondition>
            </FilterRow>
            <FilterRow>
                <FilterCondition label="グレード" labelWidth={FIRST_LABEL_WIDTH}>
                    <MultiSelect
                        values={filterCondition.grades}
                        setItems={(v) => updateFilterCondition({ grades: v })}
                    >
                        {[...grades].sort().map((v) => (
                            <MenuItem key={v} value={v}>
                                {v}
                            </MenuItem>
                        ))}
                    </MultiSelect>
                </FilterCondition>
                <FilterCondition
                    flexGrow={1}
                    label={
                        <FlexBox>
                            キーワード
                            <HelpTip
                                title={
                                    <Box m={1}>
                                        以下の項目にマッチします。
                                        <List dense disablePadding>
                                            <ListItem>ヒトノワID</ListItem>
                                            <ListItem>氏名（漢字、よみがな、英語表記）</ListItem>
                                            <ListItem>ニックネーム</ListItem>
                                            <ListItem>Slack名</ListItem>
                                            <ListItem>メールアドレス（エイリアス含む）</ListItem>
                                            <ListItem>GitHubID</ListItem>
                                            <ListItem>出向先の企業名</ListItem>
                                            <ListItem>役職</ListItem>
                                        </List>
                                    </Box>
                                }
                            />
                        </FlexBox>
                    }
                >
                    <Box flexGrow={1}>
                        <TextField
                            fullWidth
                            placeholder="名前やメールアドレス、出向先の企業名などで部分検索できます。"
                            value={filterCondition.name}
                            onChange={(e) => {
                                updateFilterCondition({
                                    name: e.target.value,
                                });
                            }}
                        />
                    </Box>
                </FilterCondition>
                <FilterCondition label="駐在区分">
                    <MultiSelect
                        values={filterCondition.secondmentType}
                        setItems={(v) => updateFilterCondition({ secondmentType: v })}
                    >
                        {[...secondmentTypes].sort().map((v) => (
                            <MenuItem key={v} value={v}>
                                {v}
                            </MenuItem>
                        ))}
                    </MultiSelect>
                </FilterCondition>
                <FilterCondition>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={filterCondition.noSlackIntegration}
                                onChange={(e) =>
                                    updateFilterCondition({
                                        noSlackIntegration: e.target.checked,
                                    })
                                }
                                name="noSlackIntegration"
                                size="small"
                            />
                        }
                        label={<Typography>Slack未連携</Typography>}
                    />
                </FilterCondition>
                <FilterCondition>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={filterCondition.noProfileImage}
                                onChange={(e) =>
                                    updateFilterCondition({
                                        noProfileImage: e.target.checked,
                                    })
                                }
                                name="noProfileImage"
                                size="small"
                            />
                        }
                        label={<Typography>画像未設定</Typography>}
                    />
                </FilterCondition>
            </FilterRow>
            <FilterRow>
                <FilterCondition label="所属" labelWidth={FIRST_LABEL_WIDTH}>
                    <TeamDrill
                        teamId={filterCondition.teamId}
                        setTeamId={(teamId) => updateFilterCondition({ teamId })}
                    />
                </FilterCondition>
            </FilterRow>
            <FilterRow>
                <FilterCondition label="タグ" labelWidth={FIRST_LABEL_WIDTH}>
                    <MultiSelect
                        values={filterCondition.tagCategories}
                        setItems={(v) =>
                            updateFilterCondition({ tagCategories: v, tagTerms: [], tagLabels: [] })
                        }
                    >
                        {[...tagCategories].sort().map((v) => (
                            <MenuItem key={v} value={v}>
                                {v}
                            </MenuItem>
                        ))}
                    </MultiSelect>
                </FilterCondition>
                {filterCondition.tagCategories.length > 0 && (
                    <>
                        <FilterCondition label="期間">
                            <MultiSelect
                                values={filterCondition.tagTerms}
                                setItems={(v) => updateFilterCondition({ tagTerms: v })}
                            >
                                {[...tagTerms].sort().map((v) => (
                                    <MenuItem key={v} value={v}>
                                        {v}
                                    </MenuItem>
                                ))}
                            </MultiSelect>
                        </FilterCondition>
                        <FilterCondition label="詳細">
                            <MultiSelect
                                values={filterCondition.tagLabels}
                                setItems={(v) => updateFilterCondition({ tagLabels: v })}
                            >
                                {[...tagLabels].sort().map((v) => (
                                    <MenuItem key={v} value={v}>
                                        {v}
                                    </MenuItem>
                                ))}
                            </MultiSelect>
                        </FilterCondition>
                    </>
                )}
            </FilterRow>
            <FilterRow>
                <FilterCondition label="在籍" labelWidth={FIRST_LABEL_WIDTH}>
                    <TextField
                        select
                        value={filterCondition.enrollment}
                        onChange={(e) => {
                            const enrollment = e.target.value;
                            if (isEnrollment(enrollment)) {
                                updateFilterCondition({
                                    enrollment,
                                });
                            }
                        }}
                    >
                        <MenuItem value="current">在職者のみ</MenuItem>
                        <MenuItem value="all">すべて表示</MenuItem>
                        <MenuItem value="leaved">退職者のみ</MenuItem>
                    </TextField>
                </FilterCondition>
                {grants.showLeavedTalent && (
                    <FilterCondition>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={filterCondition.showSuspended}
                                    onChange={(e) =>
                                        updateFilterCondition({
                                            showSuspended: e.target.checked,
                                        })
                                    }
                                    name="showSuspended"
                                    size="small"
                                />
                            }
                            label={<Typography>停止済みも表示</Typography>}
                        />
                    </FilterCondition>
                )}
            </FilterRow>
        </>
    );
};

const Treasure2024 = (_props: Props) => {
    const { filterCondition, updateFilterCondition } = useFilterFormContext();
    const { grants } = useMeContext();
    return (
        <>
            <FilterRow>
                <FilterCondition
                    flexGrow={1}
                    label={
                        <FlexBox>
                            キーワード
                            <HelpTip
                                title={
                                    <Box m={1}>
                                        以下の項目にマッチします。
                                        <List dense disablePadding>
                                            <ListItem>ヒトノワID</ListItem>
                                            <ListItem>氏名（漢字、よみがな、英語表記）</ListItem>
                                            <ListItem>ニックネーム</ListItem>
                                            <ListItem>Slack名</ListItem>
                                            <ListItem>メールアドレス（エイリアス含む）</ListItem>
                                            <ListItem>GitHubID</ListItem>
                                            <ListItem>役職</ListItem>
                                        </List>
                                    </Box>
                                }
                            />
                        </FlexBox>
                    }
                >
                    <Box flexGrow={1}>
                        <TextField
                            fullWidth
                            placeholder="名前やメールアドレス、GitHubのIDなど各種項目で部分検索できます。"
                            value={filterCondition.name}
                            onChange={(e) => {
                                updateFilterCondition({
                                    name: e.target.value,
                                });
                            }}
                        />
                    </Box>
                </FilterCondition>
                <FilterCondition>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={filterCondition.noSlackIntegration}
                                onChange={(e) =>
                                    updateFilterCondition({
                                        noSlackIntegration: e.target.checked,
                                    })
                                }
                                name="noSlackIntegration"
                                size="small"
                            />
                        }
                        label={<Typography>Slack未連携</Typography>}
                    />
                </FilterCondition>
                <FilterCondition>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={filterCondition.noProfileImage}
                                onChange={(e) =>
                                    updateFilterCondition({
                                        noProfileImage: e.target.checked,
                                    })
                                }
                                name="noProfileImage"
                                size="small"
                            />
                        }
                        label={<Typography>画像未設定</Typography>}
                    />
                </FilterCondition>
            </FilterRow>
            <FilterRow>
                <FilterCondition label="所属" labelWidth={FIRST_LABEL_WIDTH}>
                    <TeamDrill
                        teamId={filterCondition.teamId}
                        setTeamId={(teamId) => updateFilterCondition({ teamId })}
                    />
                </FilterCondition>
            </FilterRow>
            {grants.showLeavedTalent && (
                <FilterRow>
                    <FilterCondition label="在籍" labelWidth={FIRST_LABEL_WIDTH}>
                        <TextField
                            select
                            value={filterCondition.enrollment}
                            onChange={(e) => {
                                const enrollment = e.target.value;
                                if (isEnrollment(enrollment)) {
                                    updateFilterCondition({
                                        enrollment,
                                    });
                                }
                            }}
                        >
                            <MenuItem value="current">在職者のみ</MenuItem>
                            <MenuItem value="all">すべて表示</MenuItem>
                            <MenuItem value="leaved">退職者のみ</MenuItem>
                        </TextField>
                    </FilterCondition>
                    <FilterCondition>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={filterCondition.showSuspended}
                                    onChange={(e) =>
                                        updateFilterCondition({
                                            showSuspended: e.target.checked,
                                        })
                                    }
                                    name="showSuspended"
                                    size="small"
                                />
                            }
                            label={<Typography>停止済みも表示</Typography>}
                        />
                    </FilterCondition>
                </FilterRow>
            )}
        </>
    );
};
