import {
    Box,
    Table,
    TableBody,
    TableCell,
    tableCellClasses,
    TableContainer,
    TableHead,
    TableRow,
    tableRowClasses,
    TableSortLabel,
    Tooltip,
} from '@mui/material';
import {
    SurveyGroup,
    SurveyMemo,
    SurveyQuestion,
    type SurveyPeriodReportResponse,
} from '@spec/Survey';
import type { TalentId } from '@spec/Talent';
import { useEffect, useRef } from 'react';
import { isLikertQuestion } from '../../../../domains/Survey';
import { findTalent } from '../../../../domains/Talent';
import { useMeContext } from '../../../../queries/me';
import { useTalentsContext } from '../../../Context';
import { Pager, sliceItems } from '../../../Pager';
import { PopoverTalent } from '../../../PopoverTalent';
import { RouterLink } from '../../../RouterLink';
import { comparer, useTableSorter, type SortDirection } from '../../../SortableTable';
import type { ResponseValue } from '../../apply/ApplyForm';
import { getReportUrl } from '../../urls';
import { useFilterFormContext } from './Context';
import { Memo } from './Memo';
import { ResponseCell } from './ResponseCell';
import type { Row, RowBase } from './types';

export const ReportTable = (props: {
    group: SurveyGroup;
    questions: SurveyQuestion[];
    rows: Row[];
    lastReport: SurveyPeriodReportResponse;
    secondLastReport: SurveyPeriodReportResponse;
    memos: SurveyMemo[];
}) => {
    const { talents } = useTalentsContext();
    const { grants } = useMeContext();
    const isOperable = grants.survey.operableSurveyGroups.includes(props.group.id);

    const { page, setPage } = useFilterFormContext();
    const { sortKey, direction, SortHeader, onSort } = useTableSorter<Row>('respondedAt', 'desc');

    const ITEMS_PER_PAGE = 100;
    const sortedRows = sortRows(props.rows, sortKey, direction);
    const rowsSlice = sliceItems(sortedRows, page, ITEMS_PER_PAGE);

    const tableContainerRef = useRef<HTMLDivElement>(null);
    const tableHeadRef = useRef<HTMLTableSectionElement>(null);
    useEffect(() => {
        function handleScroll() {
            const container = tableContainerRef.current;
            const thead = tableHeadRef.current;
            if (container && thead) {
                const containerRect = container.getBoundingClientRect();
                if (containerRect.top < 0) {
                    thead.style.transform = `translateY(${containerRect.top * -1}px)`;
                } else {
                    thead.style.transform = '';
                }
            }
        }
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <Box>
            <Pager
                current={page}
                setPage={setPage}
                amount={props.rows.length}
                perItems={ITEMS_PER_PAGE}
            />
            <TableContainer ref={tableContainerRef}>
                <Table
                    stickyHeader
                    sx={(theme) => ({
                        [`& .${tableCellClasses.root}`]: {
                            backgroundColor: theme.palette.background.default,
                        },
                        [`& .${tableRowClasses.hover}:hover .${tableCellClasses.root}`]: {
                            backgroundColor: theme.palette.action.hover,
                        },
                    })}
                >
                    <TableHead ref={tableHeadRef} sx={{ position: 'relative', zIndex: 20 }}>
                        <TableRow>
                            <SortHeader sortKey="respondedAt">回答日時</SortHeader>
                            <SortHeader sortKey="employeeCode">社員番号</SortHeader>
                            <SortHeader
                                sortKey="name"
                                sx={{
                                    position: 'sticky',
                                    left: 0,
                                    zIndex: 40,
                                }}
                            >
                                氏名
                            </SortHeader>
                            {props.questions.map((question) => (
                                <TableCell
                                    key={question.id}
                                    sx={isLikertQuestion(question) ? { px: 0.5 } : {}}
                                >
                                    <Tooltip arrow title={question.title} placement="top">
                                        <TableSortLabel
                                            active={sortKey === question.id}
                                            direction={direction}
                                            onClick={() => onSort(question.id)}
                                        >
                                            {question.shortTitle}
                                        </TableSortLabel>
                                    </Tooltip>
                                </TableCell>
                            ))}
                            {isOperable === true && (
                                <SortHeader sortKey="lastMemoedAt">最新メモ</SortHeader>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rowsSlice.map((v) => (
                            <TableRow key={v.employeeCode} hover>
                                <TableCell>
                                    {v.respondedAt ? v.respondedAt.format('MM/DD HH:mm') : '-'}
                                </TableCell>
                                <TableCell>{v.employeeCode}</TableCell>
                                <TableCell
                                    sx={{
                                        position: 'sticky',
                                        left: 0,
                                        zIndex: 10,
                                    }}
                                >
                                    <PopoverTalent talent={findTalent(v.talentId, talents)}>
                                        <RouterLink
                                            variant="body2"
                                            to={getReportUrl(props.group.id, v.employeeCode)}
                                        >
                                            {v.name}
                                        </RouterLink>
                                    </PopoverTalent>
                                </TableCell>
                                {props.questions.map((question) => (
                                    <ResponseCell
                                        key={question.id}
                                        response={v[question.id]}
                                        lastResponse={getLastScore(
                                            props.lastReport,
                                            v.talentId,
                                            question.id
                                        )}
                                        secondLastResponse={getLastScore(
                                            props.secondLastReport,
                                            v.talentId,
                                            question.id
                                        )}
                                    />
                                ))}
                                {isOperable === true && (
                                    <TableCell>
                                        <Memo
                                            group={props.group}
                                            memos={props.memos.filter(
                                                (m) => m.respondentTalentId === v.talentId
                                            )}
                                        />
                                    </TableCell>
                                )}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Pager
                current={page}
                setPage={setPage}
                amount={props.rows.length}
                perItems={ITEMS_PER_PAGE}
            />
        </Box>
    );
};

const getLastScore = (
    report: SurveyPeriodReportResponse,
    talentId: TalentId,
    questionId: number
): number | null => {
    const res = report.responses
        .find((v) => v.talentId === talentId)
        ?.responses.find((v) => v.questionId === questionId);
    if (res === undefined) {
        return null;
    }
    return 'score' in res ? res.score : null;
};

const getValueForSort = (
    value: ResponseValue | undefined | null
): string | number | boolean | null => {
    if (Array.isArray(value)) {
        return value.join(', ');
    }
    return value ?? null;
};

const getScore = (questionId: number, response: RowBase): number => {
    const x = response[questionId];
    if (x === undefined) {
        return 0;
    }
    return typeof x === 'number' ? x : 0;
};

export const sortRows = (rows: Row[], sortKey: keyof Row, direction: SortDirection) =>
    [...rows].sort((a, b) => {
        const order = direction === 'asc' ? 1 : -1;
        if (typeof sortKey === 'number') {
            const aa = getValueForSort(a[sortKey]);
            const bb = getValueForSort(b[sortKey]);
            if (aa === null && bb === null) {
                return 0;
            }
            if (typeof aa === 'string' || typeof bb === 'string') {
                const aaa = aa ?? '';
                const bbb = bb ?? '';
                if (aa === bb) {
                    return 0;
                }
                return aaa > bbb ? order : -order;
            }
            if (typeof aa === 'boolean' || typeof bb === 'boolean') {
                const aaa = aa ?? false;
                const bbb = bb ?? false;
                if (aa === bb) {
                    return 0;
                }
                return aaa > bbb ? order : -order;
            }
            return (getScore(sortKey, a) - getScore(sortKey, b)) * order;
        }
        return comparer(a, b, sortKey, 'employeeCode', direction);
    });
