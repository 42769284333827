import { TableCell, Typography } from '@mui/material';
import { SURVEY_UNSURE_SCORE } from '@spec/Survey';
import { CheckIcon } from '../../../Icons';
import type { ResponseValue } from '../../apply/ApplyForm';

export const ResponseCell: React.FC<{
    response: ResponseValue | null;
    lastResponse: number | null;
    secondLastResponse: number | null;
}> = (props) => {
    switch (typeof props.response) {
        case 'number':
            return (
                <TableCell align="right" sx={{ pl: 0, pr: 2.5 }}>
                    {props.secondLastResponse !== null && (
                        <Typography display="inline" variant="caption" color="textDisabled">
                            {props.secondLastResponse}
                            {props.lastResponse === null ? ' - . - ' : ' - '}
                        </Typography>
                    )}
                    {props.lastResponse !== null && (
                        <Typography display="inline" variant="caption" color="textDisabled">
                            {props.lastResponse}
                            {' - '}
                        </Typography>
                    )}
                    {props.response === SURVEY_UNSURE_SCORE ? '-' : props.response}
                </TableCell>
            );
        case 'string':
            return <TableCell sx={{ whiteSpace: 'pre-wrap' }}>{props.response}</TableCell>;
        case 'boolean':
            return <TableCell>{props.response && <CheckIcon fontSize="small" />}</TableCell>;
        default:
            // select question
            if (Array.isArray(props.response)) {
                return <TableCell>{props.response.join(', ')}</TableCell>;
            }
            // null
            return <TableCell />;
    }
};
