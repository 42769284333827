import { SurveyPermissions } from '@spec/Grants';
import { Team } from '@spec/Organization';
import {
    SurveyBooleanQuestion,
    SurveyBooleanResponse,
    SurveyGroup,
    SurveyLikertQuestion,
    SurveyLikertResponse,
    SurveyPeriod,
    SurveyQuestion,
    SurveyResponse,
    SurveyTextQuestion,
    SurveyTextResponse,
    type Survey,
    type SurveySelectQuestion,
    type SurveySelectResponse,
} from '@spec/Survey';
import type { SurveyNotification } from '@spec/notification';
import { Dayjs } from 'dayjs';
import { getApplySurveyUrl } from '../components/survey/urls';
import { findById } from '../lib/ArrayUtils';
import type { Announcement } from './Todo';

export const isLikertQuestion = (q: SurveyQuestion): q is SurveyLikertQuestion =>
    Object.keys(q).includes('minLabel');

export const isBooleanQuestion = (q: SurveyQuestion): q is SurveyBooleanQuestion =>
    Object.keys(q).includes('label');

export const isSelectQuestion = (q: SurveyQuestion): q is SurveySelectQuestion =>
    Object.keys(q).includes('maxSelections');

export const isTextQuestion = (q: SurveyQuestion): q is SurveyTextQuestion =>
    !isLikertQuestion(q) && !isBooleanQuestion(q) && !isSelectQuestion(q);

export const isTextResponse = (v: SurveyResponse): v is SurveyTextResponse =>
    Object.keys(v).includes('message');

export const isLikertResponse = (v: SurveyResponse): v is SurveyLikertResponse =>
    Object.keys(v).includes('score');

export const isBooleanResponse = (v: SurveyResponse): v is SurveyBooleanResponse =>
    Object.keys(v).includes('checked');

export const isSelectResponse = (v: SurveyResponse): v is SurveySelectResponse =>
    Object.keys(v).includes('options');

export const isFulfilledResponse = (v: SurveyResponse): boolean => {
    if (isTextResponse(v)) {
        return v.message !== '';
    }
    if (isBooleanResponse(v)) {
        return v.checked;
    }
    if (isSelectResponse(v)) {
        return v.options.length > 0;
    }
    return true;
};

export const findActivePeriod = (
    surveyGroup: SurveyGroup,
    currentTime: Dayjs
): SurveyPeriod | null =>
    surveyGroup.periods.find(
        (v) => currentTime.isBetween(v.openedAt, v.closedAt, null, '[)') && v.questions.length > 0
    ) ?? null;

export const accessibleSurveyGroupIds = (
    surveys: Survey[],
    permissions: SurveyPermissions
): number[] => {
    const readableSurveyIds = Object.keys(permissions.surveyReadPermissions).map(Number);
    if (readableSurveyIds.length === 0 && permissions.operableSurveyGroups.length === 0) {
        return [];
    }
    const groupIds = new Set(permissions.operableSurveyGroups);
    for (const surveyId of readableSurveyIds) {
        groupIds.add(findById(surveyId, surveys).surveyGroupId);
    }
    return [...groupIds].sort((a, b) => a - b);
};

export const getReadableTeams = (
    survey: Survey,
    teams: Team[],
    permissions: SurveyPermissions
): Team[] => {
    if (permissions.operableSurveyGroups.includes(survey.surveyGroupId)) {
        return teams.filter((v) => v.parentId === null);
    }
    const readableTeams = (permissions.surveyReadPermissions[survey.id] ?? []).map((teamId) =>
        findById(teamId, teams)
    );
    return readableTeams;
};

export const isReadableSurvey = (survey: Survey, permissions: SurveyPermissions): boolean => {
    if (permissions.operableSurveyGroups.includes(survey.surveyGroupId)) {
        return true;
    }
    return permissions.surveyReadPermissions[survey.id] !== undefined;
};

export const buildAnnouncement = (v: SurveyNotification): Announcement[] => {
    const f = (v: SurveyNotification, contents: string[]): Announcement => ({
        id: v.id,
        key: '',
        title: [v.payload.group.name, v.payload.period.name].join(' '),
        publishedAt: v.occurredAt,
        contents,
        action: {
            label: '今すぐ回答する',
            url: getApplySurveyUrl(v.payload.group.id),
        },
    });
    switch (v.action) {
        case 'open':
            return [
                f(v, [
                    '新しいサーベイが公開されました。',
                    '実施中のサーベイは、メニューの「ダッシュボード」または「サーベイ」から一覧で確認できます。',
                ]),
            ];
        case 'remind':
            return [
                f(v, [
                    'サーベイの回答期限まで一週間を切りました。',
                    '実施中のサーベイは、メニューの「ダッシュボード」または「サーベイ」から一覧で確認できます。',
                ]),
            ];
        default:
            return [];
    }
};
