import { Box, Stack, Typography } from '@mui/material';
import type { Team } from '@spec/Organization';
import {
    SurveyGroup,
    SurveyMemo,
    type SurveyCompactResponse,
    type SurveyPeriod,
    type SurveyPeriodReportResponse,
} from '@spec/Survey';
import { type Talent } from '@spec/Talent';
import dayjs from 'dayjs';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { findBaseTeam, generateBreadcrumbs, getCorporate } from '../../../../domains/Organization';
import { findTalent, fullName, gradeToString, isEnrolledTalent } from '../../../../domains/Talent';
import { findById, sortByKey } from '../../../../lib/ArrayUtils';
import { useStateValue } from '../../../../lib/Context';
import { queryToArray } from '../../../../queries';
import { useSurveyMemos, useSurveyPeriodReport } from '../../../../queries/survey';
import { useCurrentTimeContext, useTalentsContext, useTeamsContext } from '../../../Context';
import { NoItems } from '../../../NoItems';
import { WaitLoading } from '../../../WaitLoading';
import { TalentSearch } from '../../../search/TalentSearch';
import type { ResponseValue } from '../../apply/ApplyForm';
import { ContextProvider, useFilterFormContext } from './Context';
import { CopyButton } from './CopyButton';
import { filterTalents } from './Filter';
import { FilterForm } from './FilterForm';
import { ReportTable } from './ReportTable';
import { ResponseFilter } from './ResponseFilter';
import type { Row, RowBase } from './types';

export const PeriodReport: React.FC<{ surveyGroup: SurveyGroup }> = (props) => {
    const [, setSearchParams] = useSearchParams();
    const { currentTime } = useCurrentTimeContext();
    return (
        <ContextProvider surveyGroup={props.surveyGroup} currentTime={currentTime}>
            <Stack spacing={2}>
                <Box>
                    <Typography>人を見る</Typography>
                    <TalentSearch
                        value={null}
                        onChange={(t) => {
                            setSearchParams({ code: t?.employment.employeeCode ?? '' });
                        }}
                    />
                </Box>
                <FilterForm surveyGroup={props.surveyGroup} />
                <Report surveyGroup={props.surveyGroup} />
            </Stack>
        </ContextProvider>
    );
};

const getLastPeriod = (surveyGroup: SurveyGroup, current: SurveyPeriod): SurveyPeriod | null => {
    const periods = sortByKey(surveyGroup.periods, 'openedAt', 'desc');
    const last = periods.find((v) => v.openedAt < current.openedAt);
    return last ?? null;
};

const Report: React.FC<{
    surveyGroup: SurveyGroup;
}> = (props) => {
    const { condition } = useFilterFormContext();
    const maybeReport = useSurveyPeriodReport(props.surveyGroup.id, condition.periodId);
    const period = findById(condition.periodId, props.surveyGroup.periods);
    const lastPeriod = getLastPeriod(props.surveyGroup, period);
    const maybeLastReport = useSurveyPeriodReport(props.surveyGroup.id, lastPeriod?.id ?? null);
    const maybeSecondLastReport = useSurveyPeriodReport(
        props.surveyGroup.id,
        lastPeriod ? (getLastPeriod(props.surveyGroup, lastPeriod)?.id ?? null) : null
    );
    const maybeMemos = useSurveyMemos(period);
    return (
        <WaitLoading waitFor={[maybeMemos, maybeReport, maybeLastReport, maybeSecondLastReport]}>
            {maybeReport.data && maybeLastReport.data && maybeSecondLastReport.data && (
                <ResponsesTable
                    group={props.surveyGroup}
                    period={period}
                    report={maybeReport.data}
                    lastReport={maybeLastReport.data}
                    secondLastReport={maybeSecondLastReport.data}
                    memos={queryToArray(maybeMemos)}
                />
            )}
        </WaitLoading>
    );
};

const getResponseValue = (v: SurveyCompactResponse): ResponseValue => {
    if ('score' in v) {
        return v.score;
    }
    if ('message' in v) {
        return v.message;
    }
    if ('checked' in v) {
        return v.checked;
    }
    return v.options;
};

const ResponsesTable: React.FC<{
    group: SurveyGroup;
    period: SurveyPeriod;
    report: SurveyPeriodReportResponse;
    lastReport: SurveyPeriodReportResponse;
    secondLastReport: SurveyPeriodReportResponse;
    memos: SurveyMemo[];
}> = (props) => {
    const { talents } = useTalentsContext();
    const { teams } = useTeamsContext();
    const { condition } = useFilterFormContext();
    const requires = useStateValue<Set<number>>(new Set());

    const questions = props.report.questions;
    const targetTalents = filterTalents(condition, talents, teams);
    const targetTalentIds = new Set(targetTalents.map((v) => v.id));
    const responses = [...props.report.responses]
        .filter((v) => targetTalentIds.has(v.talentId))
        .map((res) => {
            const row: RowBase = {
                respondedAt: dayjs(res.respondedAt),
                lastMemoedAt:
                    props.memos
                        .find((m) => m.respondentTalentId === res.talentId)
                        ?.publishedAt.getTime() ?? 0,
                talentId: res.talentId,
            };
            for (const q of questions) {
                const v = res.responses.find((v) => v.questionId === q.id);
                row[q.id] = v === undefined ? null : getResponseValue(v);
            }
            return row;
        });
    if (condition.notApplied === true) {
        for (const talent of targetTalents) {
            if (
                isEnrolledTalent(talent) &&
                responses.find((row) => row.talentId === talent.id) === undefined
            ) {
                // without any responses
                responses.push({
                    respondedAt: null,
                    lastMemoedAt:
                        props.memos
                            .find((m) => m.respondentTalentId === talent.id)
                            ?.publishedAt.getTime() ?? 0,
                    talentId: talent.id,
                });
            }
        }
    }
    const rows: Row[] = generateRows(responses, talents, teams).filter((row) =>
        [...requires.value].every((questionId) => {
            const res = row[questionId];
            switch (typeof res) {
                case 'string':
                    return res !== '';
                case 'boolean':
                    return res === true;
                default:
                    return false;
            }
        })
    );
    return (
        <Box>
            <ResponseFilter questions={questions} requires={requires} />
            {rows.length === 0 ? (
                <NoItems mt={4}>条件に合致する回答はありません</NoItems>
            ) : (
                <Box>
                    <Box my={2}>{rows.length}人が回答しました</Box>
                    <CopyButton
                        group={props.group}
                        questions={questions}
                        rows={rows}
                        lastReport={props.lastReport}
                        secondLastReport={props.secondLastReport}
                    />
                    <ReportTable
                        group={props.group}
                        questions={questions}
                        rows={rows}
                        lastReport={props.lastReport}
                        secondLastReport={props.secondLastReport}
                        memos={props.memos}
                    />
                </Box>
            )}
        </Box>
    );
};

const generateRows = (responses: RowBase[], talents: Talent[], teams: Team[]): Row[] => {
    return responses.map((v) => {
        const talent = findTalent(v.talentId, talents);
        const team = findById(talent.teamId, teams);
        const baseTeam = findBaseTeam(talent, teams);
        const corporate = getCorporate(talent, teams);
        const breadcrumbs = generateBreadcrumbs(team.id, teams, corporate.id);
        const row: Row = {
            ...v,
            employeeCode: talent.employment.employeeCode,
            name: fullName(talent),
            teamName: team.name,
            baseTeamName: baseTeam?.name ?? team.name,
            corporateName: corporate.name,
            teams: breadcrumbs,
            position: talent.position,
            employmentStatus: talent.employment.employmentStatus,
            grade: gradeToString(talent.grade),
            joinedAt: dayjs(talent.joinedAt),
            leftAt: talent.employment.leavedAt === null ? null : dayjs(talent.employment.leavedAt),
            isNewGraduate: talent.isNewGraduate,
        };
        return row;
    });
};
