import {
    Alert,
    Box,
    Button,
    Card,
    CardContent,
    Container,
    DialogContent,
    DialogTitle,
    Typography,
} from '@mui/material';
import type {
    Survey,
    SurveyApplyingItem,
    SurveyGroup,
    SurveyPeriod,
    SurveyQuestion,
} from '@spec/Survey';
import { memo, useCallback, useState } from 'react';
import {
    isBooleanQuestion,
    isLikertQuestion,
    isSelectQuestion,
    isTextQuestion,
} from '../../../domains/Survey';
import { ApplicationError } from '../../../Errors';
import { useEmptyForm } from '../../../lib/Form';
import { useApplySurvey } from '../../../queries/survey';
import { FormTheme } from '../../../Theme';
import { ActionContainer } from '../../ActionButtons';
import { Markdown } from '../../Markdown';
import { SubTitle } from '../../PageTitle';
import { DialogActionButtons, StableDialog } from '../../StableDialog';
import { TenantContent } from '../../TenantContent';
import { useSurveysContext } from '../Context';
import { BooleanQuestionForm } from './BooleanQuestionForm';
import { LikertQuestionForm } from './LikertQuestionForm';
import { SelectQuestionForm } from './SelectQuestionForm';
import { TextQuestionForm } from './TextQuestionForm';

export type ResponseMap = Map<number, ResponseValue>;
export type ResponseValue = number | boolean | string | string[];
export type ResponseSetter = (k: number, v: ResponseValue | null) => void;

export const useSurveyResponses = () => {
    const [responses, _setResponses] = useState<ResponseMap>(new Map());
    const setResponse: ResponseSetter = useCallback(
        (k, v) =>
            _setResponses((prev) => {
                const x = new Map(prev);
                if (v === '' || v === false || v === null) {
                    x.delete(k);
                } else {
                    x.set(k, v);
                }
                return x;
            }),
        []
    );
    return [responses, setResponse] as const;
};

export const ApplyForm: React.FC<{
    group: SurveyGroup;
    period: SurveyPeriod;
}> = (props) => {
    const form = useEmptyForm();
    const { surveys } = useSurveysContext();
    const [responses, setResponse] = useSurveyResponses();
    const [openDialog, setOpenDialog] = useState(false);
    const closeDialog = useCallback(() => {
        setOpenDialog(false);
    }, []);
    const mutation = useApplySurvey(props.group.id);
    return (
        <Box>
            {surveys
                .filter((v) => v.surveyGroupId === props.group.id)
                .map((v) => (
                    <SurveyApplyForm
                        key={v.id}
                        period={props.period}
                        survey={v}
                        setResponse={setResponse}
                    />
                ))}
            <FormTheme>
                <ActionContainer>
                    <Button
                        variant="contained"
                        size="medium"
                        fullWidth
                        disabled={responses.size === 0 || form.succeeded === true}
                        onClick={() => setOpenDialog(true)}
                    >
                        サーベイに回答する
                    </Button>
                </ActionContainer>
                {form.succeeded === true && (
                    <Box mt={2}>
                        <Container maxWidth="md">
                            <Alert severity="success">サーベイに回答しました</Alert>
                        </Container>
                    </Box>
                )}
                <StableDialog
                    open={openDialog}
                    noClose
                    onClose={closeDialog}
                    maxWidth="sm"
                    fullWidth
                >
                    <DialogTitle>{props.group.name}に回答する</DialogTitle>
                    <DialogContent>
                        <Typography>
                            回答結果は上書きできません。今の内容で確定しますか？
                        </Typography>
                        <TenantContent
                            carta={
                                <Alert severity="warning" sx={{ mt: 2 }}>
                                    「CARTAパルスサーベイ」の設問は事業部の上長も閲覧可能です
                                </Alert>
                            }
                        />
                    </DialogContent>
                    <DialogActionButtons
                        form={form}
                        submitLabel="今の内容で回答を確定する"
                        cancelLabel="入力画面に戻る"
                        errorMessage="回答に失敗しました"
                        onSubmit={() => mutation.mutateAsync({ responses: toItems(responses) })}
                        closeDialog={closeDialog}
                    />
                </StableDialog>
            </FormTheme>
        </Box>
    );
};

const toItems = (x: ResponseMap): SurveyApplyingItem[] => {
    const items: SurveyApplyingItem[] = [];
    const toItem = (questionId: number, value: ResponseValue): SurveyApplyingItem => {
        switch (typeof value) {
            case 'number':
                return { questionId, score: value };
            case 'boolean':
                return { questionId, checked: value };
            case 'string':
                return { questionId, message: value };
            default:
                if (Array.isArray(value)) {
                    return { questionId, options: value };
                }
                throw new ApplicationError(
                    `unexpected response for question ${questionId}: ${value}`
                );
        }
    };
    for (const [questionId, response] of x.entries()) {
        items.push(toItem(questionId, response));
    }
    return items;
};

const SurveyApplyForm: React.FC<{
    period: SurveyPeriod;
    survey: Survey;
    setResponse: ResponseSetter;
}> = memo((props) => {
    const surveyQuestionIds = props.survey.questions.map((v) => v.id);
    const questions = props.period.questions.filter((v) => surveyQuestionIds.includes(v.id));
    return (
        <Box mt={2}>
            <Container maxWidth="md">
                <SubTitle title={props.survey.name} />
                <Markdown source={props.survey.description} variant="body2" />
                {questions.map((q) => (
                    <QuestionForm key={q.id} question={q} setResponse={props.setResponse} />
                ))}
            </Container>
        </Box>
    );
});

const QuestionForm: React.FC<{
    question: SurveyQuestion;
    setResponse: ResponseSetter;
}> = (props) => {
    const q = props.question;
    return (
        <Box mt={2}>
            <Card>
                <CardContent>
                    <Typography>{q.title}</Typography>
                    {isLikertQuestion(q) && (
                        <LikertQuestionForm question={q} setResponse={props.setResponse} />
                    )}
                    {isBooleanQuestion(q) && (
                        <BooleanQuestionForm question={q} setResponse={props.setResponse} />
                    )}
                    {isTextQuestion(q) && (
                        <TextQuestionForm question={q} setResponse={props.setResponse} />
                    )}
                    {isSelectQuestion(q) && (
                        <SelectQuestionForm question={q} setResponse={props.setResponse} />
                    )}
                </CardContent>
            </Card>
        </Box>
    );
};
